import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <div className="hero-panel">
      <div className="studio-title">Banana Byte Games</div>
    </div>
  </Layout>
)

export default IndexPage
